import React, { useState, useEffect } from "react";
import Layout from "Components/Layout";
import NotificationClasses from "./Notifications.module.css";
import Swal from "sweetalert2";
import {
    getNotification,
    updateUnreadNotification,
} from "Services/ServiceRequest";
import User from "assets/user.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "Components/Loader/Loader";
import moment from "moment";
import ReactLoading from "react-loading";
import useStore from "Store";
import ModalCustom from "./Modal";
function Notifications() {
    const [allNotifications, setAllNotifications] = useState([]);
    const [placeId, setplaceId] = useState(null);
    const [name, setname] = useState(null);
    const [date, setDate] = useState(null);
    const [modal, setModal] = useState(false);
    const [initial, setInitial] = useState(true);
    const [body, setBody] = useState({});
    const [loading, setLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const { setNotificationCount } = useStore((state) => state);
    const updateUnread = async () => {
        try {
            const response = await updateUnreadNotification();
            setLoading(false);
            if (response?.data?.Status === 1) {
                setNotificationCount(0);
            } else {
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    const GetRequests = async (body) => {
        // setLoading(true);
        if (!hasMore) return;
        try {
            const response = await getNotification(offset, body);
            setLoading(false);
            if (response?.data?.Status === 1) {
                setAllNotifications((prev) => {
                    let allNotifications = [
                        ...prev,
                        ...response?.data?.AdminNotifications,
                    ];
                    if (response.data?.Offset == -1) {
                        setHasMore(false);
                        // updateUnread();
                    } else {
                        setOffset((prev) => prev + 30);
                    }
                    return allNotifications;
                });
            } else {
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    useEffect(() => {
        let FormData = { notificationDate: moment().format("YYYY-MM-DD") };
        if (initial) {
            setBody(FormData);
            setInitial(false);
        } else {
            GetRequests(body);
        }
    }, [body]);
    const clearhandler = () => {
        setBody({});
        window.scrollTo({ top: 0 });
        setname(null);
        setDate(null);
        setplaceId(null);
        setOffset(0);
        setAllNotifications([]);
        setHasMore(true);
    };
    return (
        <Layout>
            {modal && (
                <ModalCustom
                    setModal={setModal}
                    showModal={modal}
                    setDate={setDate}
                    date={date}
                    setname={setname}
                    name={name}
                    placeId={placeId}
                    setplaceId={setplaceId}
                    setBody={setBody}
                    setOffset={setOffset}
                    setHasMore={setHasMore}
                    setAllNotifications={setAllNotifications}
                />
            )}
            <button
                className={NotificationClasses["Modal_filter"]}
                onClick={() => setModal(true)}>
                Filter
            </button>
            <div className={"Filters_wrapper"}>
                {Object.entries(body).map((item) => (
                    <div className={"Filters"}>
                        <h3>{item[0]}</h3>

                        <p>{item[1]}</p>
                    </div>
                ))}
                {Object.entries(body).length > 0 && (
                    <button className="clear_filter_btn" onClick={clearhandler}>
                        clear
                    </button>
                )}
            </div>
            <Loader loading={loading}>
                <div className={NotificationClasses["Container"]}>
                    {/* <Loader /> */}
                    <h3>Notifications</h3>
                    {allNotifications.length > 0 ? (
                        <InfiniteScroll
                            dataLength={allNotifications.length}
                            next={GetRequests}
                            hasMore={hasMore}
                            className={
                                NotificationClasses["Notification-container"]
                            }
                            loader={
                                <div
                                    className={
                                        NotificationClasses["Notification-load"]
                                    }>
                                    {" "}
                                    <ReactLoading
                                        color="blue"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                            }>
                            {allNotifications.map((item) => (
                                <div
                                    className={
                                        NotificationClasses["Notification"]
                                    }>
                                    <img src={User} alt="" />
                                    <div
                                        className={
                                            NotificationClasses["content"]
                                        }>
                                        {/* <h4>{item?.fullName}</h4> */}
                                        <h5>
                                            {item?.fullName} (placeID:
                                            {item.placeId})
                                        </h5>
                                        <p>{item?.message}</p>
                                        <p>
                                            {moment(item?.createdAt).fromNow()}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </InfiniteScroll>
                    ) : (
                        <p className={NotificationClasses["no-new"]}>
                            No New Notifications
                        </p>
                    )}
                </div>
            </Loader>
        </Layout>
    );
}

export default Notifications;
